
import { useRef, useEffect } from "react";
import $ from 'jquery';
import { UpdateKeyValue, UpdateUserProfile } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

function ProfilePic({ user }) {
    const dispatch = useDispatch();
    const input_image = useRef();
    const updateKeyValue = (key, value, id) => {
        dispatch(UpdateKeyValue(key, value, id));
    }
    const readURL = (input) => {

        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                // console.log(e.target.result)
                updateKeyValue('thumb', e.target.result, user['id']);
                updateKeyValue('new_image', true, user['id']);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }
    useEffect(() => {
        $(input_image.current).change(function (e) {
            readURL(this);
        });
    }, [])
    return (
        <div style={{ position: 'relative' }}>
            <input style={{ display: 'none' }} ref={input_image} type="file" accept="image/*" />
            <img style={{ height: '225px', margin: 'auto' }} src={user['thumb'] ? user['thumb'] : "https://www.w3schools.com/howto/img_avatar.png"} />
            <button style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 99 }} onClick={() => {
                // setEnableedit(true);
                $(input_image.current).trigger('click');
            }} type="button" className="btn btn-sm  btn-success">upload</button>
        </div>
    );
}

export default ProfilePic;
