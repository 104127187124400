





const ImageGrid = ({ images }) => {
    return (
        <div className="row">
            {
                images.map(image => {
                    return <div key={image['id']} className="col-sm-6">
                        <img style={
                            {
                                width: "100px",
                                height: "100px",
                                objectFit: "cover"
                            }
                        } className="img-thumbnail" src={image['url']} />
                    </div>
                })
            }


        </div>
    );
}

function SingleApproval({ approval, changeApprovalStatus }) {


    const changeStatus = async () => {
        await changeApprovalStatus({
            id: approval['id'],
            status: 1
        }, approval['status']);
    }
    return (
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <div className="card shadow-sm">
                <div className="card-body">
                    <p className="card-text">{approval['title']}</p>
                    <ImageGrid images={approval["assets"] ? approval["assets"] : []} />
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
                            <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button>
                            {
                                approval['status'] === 0 && <button onClick={async () => {
                                    await changeStatus();
                                }} type="button" className="btn btn-sm btn-outline-secondary">Submit</button>
                            }
                        </div>
                        <small className="text-muted">9 mins</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleApproval;
