import { useState } from 'react';
import { UpdateKeyValue, UpdateUserProfile, UpdateUserProfileImage } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
function UpdateUser({ user, setEnableedit }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const updateKeyValue = (key, value, id) => {
        dispatch(UpdateKeyValue(key, value, id));
    }

    const updateProfile = async () => {
        setLoading(true);

        await dispatch(UpdateUserProfile(
            {
                id: user['id'],
                first_name: user['first_name'],
                last_name: user['last_name'],
                email: user['email'],
                password: user['password']
            }));
        if (user['new_image']) {
            await dispatch(UpdateUserProfileImage(
                {
                    user_id: user['id'],
                    image: user['thumb']
                }));
        }
        setLoading(false);
        setEnableedit(false);
    }
    return (

        <>
            <div className="mb-3">

                <input onChange={(e) => {
                    updateKeyValue('first_name', e.target.value, user['id']);

                }} value={user['first_name']} type="text" className="form-control mt-2" placeholder="First Name" />

                <input onChange={(e) => {
                    updateKeyValue('last_name', e.target.value, user['id']);

                }} value={user['last_name']} type="text" className="form-control mt-2" placeholder="Last Name" />

                <input onChange={(e) => {
                    updateKeyValue('email', e.target.value, user['id']);

                }} value={user['email']} type="text" className="form-control mt-2" placeholder="Email" />


                <input onChange={(e) => {
                    updateKeyValue('password', e.target.value, user['id']);

                }} value={``} type="password" className="form-control mt-2" placeholder="Password" />

            </div>

            <button onClick={() => {
                updateProfile();
            }} type="button" className="w-100 btn btn-lg btn-primary">

                {
                    loading ? <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : 'Save'
                }
            </button>
        </>

    );
}

export default UpdateUser;