export const FETCH_GLOBAL_TRANSACTION = 'FETCH_GLOBAL_TRANSACTION';
export const ADD_REFILL = 'ADD_REFILL';
export const SAVE_SETTING = 'ADD_REFILL';
export const FETCH_SETTING = 'FETCH_SETTING';

export const fetchGlobalBalance = (currency_id) => {
    return {
        type: FETCH_GLOBAL_TRANSACTION,
        payload: {
            request: {
                url: `api/v1/transactions/global/balance?currency_id=${currency_id}`,
                method: 'get',
            },
        },
    };
};




export const saveRefill = (data) => {
    return {
        type: ADD_REFILL,
        payload: {
            request: {
                url: 'api/v1/transactions/inward',
                method: 'post',
                data: data,
            },
        },
    };
};



export const saveSetting = (data) => {
    return {
        type: SAVE_SETTING,
        payload: {
            request: {
                url: 'api/v1/settings/service',
                method: 'post',
                data: data,
            },
        },
    };
};



export const fetchSetting = (identifier) => {
    return {
        type: FETCH_SETTING,
        payload: {
            request: {
                url: `api/v1/settings/service?identifier=${identifier}`,
                method: 'get'
            },
        },
    };
};


