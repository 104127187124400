import { useNavigate } from "react-router-dom";


function NavArea() {
    const history = useNavigate();

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3 sidebar-sticky">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#" onClick={(e) => {
                            e.preventDefault();
                            history("");
                        }}>
                            <span data-feather="home" className="align-text-bottom"></span>
                            Dashboard
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            history("/currencies");
                        }}>
                            <span data-feather="file" className="align-text-bottom"></span>
                            Currencies
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="#" onClick={(e) => {
                            e.preventDefault();
                            history("/users");
                        }}>
                            <span data-feather="file" className="align-text-bottom"></span>
                            Users
                        </a>
                    </li>

                </ul>
            </div>
        </nav>
    );
}

export default NavArea;
