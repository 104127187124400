
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { saveCurrency } from '../actions';
function AddCurrency() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const saveCurrencyM = async (name) => {
        if (name === '') {
            return;
        }
        setLoading(true);
        await dispatch(saveCurrency({ name: name }));
        setName('');
        setLoading(false);

    }
    // console.log(name);
    return (
        <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-bg-primary border-primary">
                    <h4 className="my-0 fw-normal">Add Currency</h4>
                </div>
                <div className="card-body">

                    <div className="mb-3">

                        <input onChange={(e) => {
                            setName(e.target.value);

                        }} value={name} type="text" className="form-control" placeholder="Currency name" />
                    </div>

                    <button onClick={() => {
                        saveCurrencyM(name);
                    }} type="button" className="w-100 btn btn-lg btn-primary">

                        {
                            loading ? <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : 'Save'
                        }
                    </button>
                </div>
            </div>
        </div >
    );
}

export default AddCurrency;
