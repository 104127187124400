import { FETCH_CURRENCIES, STORE_CURRENCIES } from '../../actions';
import { FETCH_GLOBAL_TRANSACTION, ADD_REFILL } from '../../actions/transaction';

const initialState = {
    data: []
};

export function currenccyReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case `${FETCH_CURRENCIES}_SUCCESS`: {
            return {
                ...state,
                data: payload.data.data,
            };
        }

        case `${STORE_CURRENCIES}_SUCCESS`: {
            return {
                ...state,
                data: [...state.data, payload.data.data],
            };
        }

        case `${FETCH_GLOBAL_TRANSACTION}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map(currency => {
                    return currency.id === payload.data.data.currency_id ? {
                        ...currency, global: {
                            balance: payload.data.data.balance,
                            WIDRAWAL_CHARGE: payload.data.data.WIDRAWAL_CHARGE,
                            TRANSFER_CHARGE: payload.data.data.TRANSFER_CHARGE
                        }
                    } : currency
                }),
            };
        }

        case `${ADD_REFILL}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map(currency => {
                    return currency.id === payload.data.data.currency_id ? {
                        ...currency, global: {
                            balance: currency['global'] ? (parseFloat(currency['global']['balance']) + parseFloat(payload.data.data.amount)) : payload.data.data.amount
                        }
                    } : currency
                }),
            };
        }



        default:
            return state;
    }
}
