import Approvals from "../components/Approvals/Approvals";
import {
    saveApproval, saveApprovalAsset
} from '../actions/approval';
import { useState } from 'react';
import UploadContainer from "../components/Approvals/UploadContainer";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
const fileTypes = ["JPG", "PNG"];

function AddApprovalScreen() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [signingfiles, setSigningfiles] = useState([]);

    const [supportingfiles, setSupportingfiles] = useState([]);


    const StoreApproval = async (title, description, signingfiles, supportingfiles) => {
        setLoading(true);
        try {
            const response = await dispatch(saveApproval({ title, description }));
            // console.log(response['payload']['data']);
            for (let i in signingfiles) {
                // console.log(signingfiles[i]);
                await dispatch(saveApprovalAsset({
                    status: 1,
                    approval_id: response['payload']['data']['data']['id'],
                    image: signingfiles[i]
                }));
            }
            for (let j in supportingfiles) {
                await dispatch(saveApprovalAsset({
                    status: 2,
                    approval_id: response['payload']['data']['data']['id'],
                    image: supportingfiles[j]
                }));
            }
            setTitle('');
            setDescription('');
            setSigningfiles([]);
            setSupportingfiles([]);
            history('/approvals');
        } catch (e) {
            console.log(e)
        }

        setLoading(false);
    }

    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Approvals</h1>
                <div className="btn-toolbar mb-2 mb-md-0">

                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar" className="align-text-bottom"></span>
                        Back
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input value={title} onChange={(e) => {
                        setTitle(e.target.value);
                    }} type="text" className="form-control" placeholder="Title" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea onChange={(e) => {
                        setDescription(e.target.value);
                    }} className="form-control" rows="3"></textarea>
                </div>

                <UploadContainer setFiles={setSigningfiles} files={signingfiles} title={'Signing Doument'} />
                <UploadContainer setFiles={setSupportingfiles} files={supportingfiles} title={'Supporting Document'} />

                <button onClick={() => {
                    StoreApproval(title, description, signingfiles, supportingfiles);
                }} type="button" className="w-100 btn btn-lg btn-primary">

                    {
                        loading ? <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : 'Save'
                    }
                </button>
            </div>
        </main>
    );
}

export default AddApprovalScreen;
