import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react'

function UploadContainer({ title, files, setFiles }) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // Do whatever you want with the file contents
                // const binaryStr = reader.result
                // console.log(e.target.result)
                setFiles(files => {
                    return [...files, e.target.result];
                });
            }
            reader.readAsDataURL(file)
        })

    }, []);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: onDrop
    });

    const RemoveFile = (index) => {

        setFiles(files => {
            return files.filter((file, key) => {
                return key !== index;
            })
        });

    }
    const filesComp = files.map((file, key) => (
        <div className="col-sm-3 mb-5" key={key} >
            <img src={file} className={`mb-3`} style={{ width: '100px', height: '150px' }} />
            <div className="clearfix"></div>
            <button onClick={() => {
                RemoveFile(key);
            }} className="btn btn-danger">Remove</button>
        </div>
    ));

    return (
        <div className="container" style={{ marginBottom: '10px' }}>
            <h1 style={{ fontSize: '20px' }}>{title}</h1>
            <div {...getRootProps({ className: 'dropzone' })} >
                <input {...getInputProps()} accept="image/png, image/jpeg" />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {
                files.length > 0 && <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {/* <h4>Files</h4> */}
                    {filesComp}
                </div>
            }
        </div>
    );
}

export default UploadContainer;
