import { FETCH_USERS, MAKE_USER, UPDATE_KEY_CHANGE, UPDATE_USER } from '../../actions';

const initialState = {

};

export function usersReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case `${MAKE_USER}_SUCCESS`: {
            return {
                ...state,
                data: [payload.data.data, ...state.data]

            };
        }
        case `${FETCH_USERS}_SUCCESS`: {
            // console.log(payload['data']['pagination']['current_page']);
            return {
                ...state,
                data: payload['data']['pagination']['current_page'] > 1 ? [...state.data, ...payload.data.data] : payload.data.data,
                pagination: payload['data']['pagination']
            };
        }
        case `${UPDATE_USER}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map(user => {
                    return user.id === payload.data.data.id ? {
                        ...user, ...payload.data.data
                    } : user
                })
            };
        }


        case UPDATE_KEY_CHANGE: {
            return {
                ...state,
                data: state.data.map(user => {
                    return user.id === payload.id ? {
                        ...user, [payload.key]: payload.value
                    } : user
                })
            };
        }





        default:
            return state;
    }
}
