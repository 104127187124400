import { useState } from 'react';

function AddRefill({ currency, RefillAmount, setPanel }) {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);

    const loadBalance = async () => {
        setLoading(true);
        await RefillAmount({ amount: amount, currency_id: currency['id'], message: 'Inward Transfer' });
        setLoading(false);
        setPanel(1);
    }
    return (
        <>
            <div className="mb-3">

                <input onChange={(e) => {
                    setAmount(e.target.value);

                }} value={amount} type="number" className="form-control" placeholder="Amount" />
            </div>

            <button onClick={async () => {
                await loadBalance();
            }} type="button" className="w-100 btn btn-lg btn-primary">

                {
                    loading ? <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> : 'ADD FUND'
                }
            </button>
        </>
    );
}

export default AddRefill;
