import Approvals from "../components/Approvals/Approvals";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchApproval, changeApprovalStatus
} from '../actions/approval';
import { useEffect } from 'react';
const arr = [
    {
        id: 0,
        title: 'Draft'
    },
    {
        id: 1,
        title: 'Pending'
    },
    {
        id: 2,
        title: 'Approved'
    },
    {
        id: 3,
        title: 'Rejected'
    }
];
function ApprovalScreen() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const approvals = useSelector(state => state.approval && state.approval);
    useEffect(() => {

        (async () => {
            for (let i = 0; i < 4; i++)
                try {
                    await dispatch(fetchApproval(i));
                } catch (e) { }

        })();
    }, []);
    const changeApprovalStatusSync = async (data, status) => {
        await dispatch(changeApprovalStatus(data, status));
    }

    // console.log(approvals);

    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Approvals</h1>
                <div className="btn-toolbar mb-2 mb-md-0">

                    <button onClick={() => {
                        history("/approvals/add");
                    }} type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar" className="align-text-bottom"></span>
                        Add Approval
                    </button>
                </div>
            </div>

            <div className="row">
                {
                    arr.map((approval, i) => {
                        return <Approvals key={i} changeApprovalStatus={changeApprovalStatusSync} approvals={approvals[i] ? approvals[i] : []} title={approval['title']} />
                    })
                }


            </div>
        </main>
    );
}

export default ApprovalScreen;
