
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCurrencies } from '../actions';
import {
    fetchGlobalBalance,
    saveRefill,
    saveSetting
} from '../actions/transaction';
import Currency from '../components/Currency';
import AddCurrency from '../components/AddCurrency';
function CurrenciesScreen() {
    const dispatch = useDispatch();



    const extractGlobalBalance = async (currency_id) => {
        await dispatch(fetchGlobalBalance(currency_id));
    }


    const RefillAmount = async (data) => {
        await dispatch(saveRefill(data));
    }


    const SaveSettings = async (data) => {
        await dispatch(saveSetting(data));
    }


    const currencies = useSelector(state => state.currency['data'] && state.currency['data']);
    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Currencies</h1>
                <div className="btn-toolbar mb-2 mb-md-0">

                    <button type="button" className="btn btn-sm btn-outline-secondary">
                        <span data-feather="calendar" className="align-text-bottom"></span>
                        Add Currency
                    </button>
                </div>
            </div>


            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                <AddCurrency />
                {
                    currencies.map(currency => {
                        return (
                            <Currency SaveSettings={SaveSettings} currency_id={currency['id']} extractGlobalBalance={extractGlobalBalance} RefillAmount={RefillAmount} key={currency['id']} currency={currency} />
                        )
                    })
                }

            </div>
        </main>
    );
}

export default CurrenciesScreen;
