
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, memo, useState } from 'react';
import { fetchGlobalBalance } from '../actions/transaction';
import { numberWithCommas } from '../Utils/util';
import AddRefill from './AddRefill';
import ServiceSettings from './ServiceSettings';
function Currency({ currency, extractGlobalBalance, RefillAmount, currency_id, SaveSettings }) {


    const [panel, setPanel] = useState(1);
    useEffect(() => {
        (async () => {
            await extractGlobalBalance(currency['id']);

        })();
    }, [currency_id]);

    return (
        <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-bg-primary border-primary">
                    <h4 className="my-0 fw-normal">{currency['name']}</h4>
                </div>
                <div className="card-body">
                    {
                        panel === 1 && <>
                            <h1 className="card-title pricing-card-title">{currency['name']}: {currency['global'] ? numberWithCommas(currency['global']['balance']) : 0}<small className="text-muted fw-light"></small></h1>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <button onClick={() => {
                                        setPanel(3);
                                    }} type="button" className="btn btn-sm btn-outline-secondary">SETTINGS</button>
                                    <button onClick={() => {
                                        setPanel(2);
                                    }} type="button" className="btn btn-sm btn-outline-secondary">Edit</button>
                                </div>
                            </div>

                        </>
                    }
                    {
                        panel === 2 && <AddRefill setPanel={setPanel} currency={currency} RefillAmount={RefillAmount} />
                    }

                    {
                        panel === 3 && <ServiceSettings extractGlobalBalance={extractGlobalBalance} setPanel={setPanel} currency={currency} SaveSettings={SaveSettings} />
                    }

                </div>
            </div>
        </div>
    );
}

export default memo(Currency);
