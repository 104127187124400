import { useState } from 'react';
import ProfilePic from './ProfilePic';
import UpdateUser from './UpdateUser';
function User({ user }) {
    const [enableedit, setEnableedit] = useState(false);
    return (

        <div className="col">
            <div className="card shadow-sm">
                {
                    !enableedit ? <>
                        <img style={{ height: '225px', margin: 'auto' }} src={user['thumb'] ? user['thumb'] : "https://www.w3schools.com/howto/img_avatar.png"} />
                        <div className="card-body">
                            <h1 style={{ fontSize: '25px' }} className="card-title pricing-card-title">{user['first_name']} {user['last_name']}</h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>{user['email']}</li>

                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
                                    <button onClick={() => {
                                        setEnableedit(true);
                                    }} type="button" className="btn btn-sm btn-outline-secondary">Edit</button>
                                </div>
                            </div>
                        </div>
                    </> :
                        <>
                            <ProfilePic user={user} />
                            <div className="card-body"><UpdateUser setEnableedit={setEnableedit} user={user} /></div>
                        </>
                }


            </div>
        </div>

    );
}

export default User;
