import storage from 'redux-persist/lib/storage'
import { applyMiddleware, compose, createStore } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { persistReducer, persistStore } from 'redux-persist';
import { axiosClient, axiosMiddlewareConfig } from '../api/axiosClient';
import rootReducer from './reducres';


const initialState = {};
// const sagaMiddleware = createSagaMiddleware();
const middlewares = [

    multiClientMiddleware({
        default: { client: axiosClient, options: axiosMiddlewareConfig },
    })
];

let storeEnhancers = compose(applyMiddleware(...middlewares));

// if (__DEV__) {
//   const Reactotron = require('../ReactotronConfig').default;
//   storeEnhancers = compose(
//     applyMiddleware(...middlewares),
//     Reactotron.createEnhancer(),
//   );
// }

const persistConfig = {
    key: 'root',
    storage: storage,
    version: 1,
    whitelist: ['auth', 'draft'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    initialState,
    storeEnhancers,
);

store.subscribe(() => { });
// sagaMiddleware.run(rootSaga);
// // export const rehydrateStore = cb => persistStore(store, null, cb);
// setInterval(async () => {

//     store.dispatch({ type: HEARTBEAT });

// }, 5000);
export const rehydrateStore = persistStore(store);
