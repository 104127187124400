
import { fetchProfile, loginSubmit } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function LoginScreen() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState({ username: 'ks_0@gmail.com', password: 'secret' });
    const handleLogin = async () => {
        setLoading(true);
        try {
            await dispatch(loginSubmit(authData['username'], authData['password']));
            const response = await dispatch(fetchProfile());
            setLoading(false);

        }
        catch (e) { }
        setLoading(false);
        // console.log(response);

    };
    return (
        <main className="form-signin w-100 m-auto">
            <div style={{ width: '40%', margin: 'auto', paddingTop: '100px' }}>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleLogin();
                }}>
                    {/* <img className="mb-4" src="/docs/5.2/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}
                    <h1 className="h3 mb-3 fw-normal">Sign in</h1>

                    <div className="form-floating">
                        <input onKeyUp={(text) => {
                            setAuthData({ ...authData, username: text });
                        }} type="text" class="form-control" id="floatingInput" placeholder="name@example.com" value={authData['username']} />
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input onKeyUp={(text) => {
                            setAuthData({ ...authData, password: text });
                        }} type="password" class="form-control" id="floatingPassword" placeholder="Password" value={authData['password']} />
                        <label for="floatingPassword">Password</label>
                    </div>

                    <div className="checkbox mb-3">
                        <label>
                            <input type="checkbox" value="remember-me" /> Remember me
                        </label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">
                        {
                            loading ? <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : 'Sign in'
                        }
                    </button>

                </form>
            </div>
        </main>
    );
}

export default LoginScreen;