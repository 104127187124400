import differenceInSeconds from "date-fns/differenceInSeconds";




export const secondsSince = (date, now = new Date()) => {
    return differenceInSeconds(now, new Date(date));
}




export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}