
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
function SearchUser({ search, setSearch, loadMoreUser, setPage }) {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    return (
        <div className='row'>
            <div className='col-sm-8'>
                <input onChange={(e) => {
                    setTitle(e.target.value);

                }} value={title} type="text" className="form-control" placeholder="Search" />

            </div>
            <div className='col-sm-4'>
                <button disabled={loading} onClick={async () => {
                    await setPage(1);
                    await setSearch(title);
                    await loadMoreUser();
                }} type="button" className="w-100 btn btn-lg btn-primary ">

                    {
                        loading ? <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : 'Search'
                    }
                </button>
            </div>
        </div>
    );
}

export default SearchUser;
