import { combineReducers } from 'redux';
import { approvalReducer } from './approvalReducer';
import { currenccyReducer } from './currenccyReducer';
import { draftReducer } from './draftReducer';
import { loginReducer } from './loginReducer';
import { postReducer } from './postReducer';
import { usersReducer } from './usersReducer';

export default combineReducers({
    auth: loginReducer,
    posts: postReducer,
    draft: draftReducer,
    currency: currenccyReducer,
    users: usersReducer,
    approval: approvalReducer
});
