import SingleApproval from "./SingleApproval";


function Approvals({ title, approvals, changeApprovalStatus }) {
    // console.log(approvals);
    return (
        <div className="col">
            <h2 style={{ textAlign: 'center' }}>{title}</h2>
            {
                approvals.map(approval => {
                    return <SingleApproval changeApprovalStatus={changeApprovalStatus} approval={approval} key={approval['id']} />
                })
            }
        </div>
    );
}

export default Approvals;
