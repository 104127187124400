import { useState, useEffect } from 'react';

function ServiceSettings({ currency, SaveSettings, setPanel, extractGlobalBalance }) {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [transfer, setTransfer] = useState(0);
    const [widrawl, setWWidrawl] = useState(0);
    useEffect(() => {
        if (currency['global']['TRANSFER_CHARGE']) {
            setTransfer(currency['global']['TRANSFER_CHARGE']['amount']);
        }
        if (currency['global']['WIDRAWAL_CHARGE']) {
            setWWidrawl(currency['global']['WIDRAWAL_CHARGE']['amount']);
        }
    }, [currency['id']]);
    const loadBalance = async () => {
        setLoading(true);
        await SaveSettings(
            {
                apply_type: 'CHARGE_PERCENT',
                currency_id: currency['id'],
                identifier: 'TRANSFER_CHARGE',
                amount: transfer
            });
        await SaveSettings(
            {
                apply_type: 'CHARGE_PERCENT',
                currency_id: currency['id'],
                identifier: 'WIDRAWAL_CHARGE',
                amount: widrawl
            });
        await extractGlobalBalance(currency['id']);
        setLoading(false);
        setPanel(1);
    }
    return (
        <>
            <div className="mb-3">
                <label>Transfer Charge</label>
                <input onChange={(e) => {
                    setTransfer(e.target.value);

                }} value={transfer} type="number" className="form-control" placeholder="Amount" />
            </div>
            <div className="mb-3">
                <label>Widrawl Charge</label>
                <input onChange={(e) => {
                    setWWidrawl(e.target.value);

                }} value={widrawl} type="number" className="form-control" placeholder="Amount" />
            </div>
            <div className="btn-group">
                <button onClick={() => {
                    setPanel(1);
                }} type="button" className="btn btn-sm btn-outline-secondary">Cancel</button>
                <button onClick={() => {
                    loadBalance();
                }} type="button" className="btn btn-sm btn-outline-secondary">

                    {
                        loading ? <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : 'Save'
                    }
                </button>
            </div>
        </>
    );
}

export default ServiceSettings;
