import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { fetchUsers } from '../actions';
import User from '../components/User';
import SearchUser from '../components/SearchUser';

function UsersScreen() {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const users = useSelector(state => state.users['data'] && state.users['data']);
    const pagination = useSelector(state => state.users['pagination'] && state.users['pagination']);

    const loadMoreUser = async () => {
        setLoading(true);
        await dispatch(fetchUsers(page, search));
        setLoading(false);
    };


    useEffect(() => {

        (async () => {
            await loadMoreUser();

        })();
    }, [page, search]);
    // console.log(search);
    return (
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dashboard</h1>
                <SearchUser setPage={setPage} search={search} setSearch={setSearch} loadMoreUser={loadMoreUser} />
            </div>

            <div className="table-responsive">

                {
                    users && <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
                        {
                            users.map(user => {
                                return <User key={user['id']} user={user} />
                            })
                        }
                    </div>
                }

                {
                    (pagination && page < pagination['last_page']) && <div style={{ width: '40%', margin: 'auto', paddingTop: '20px' }}>
                        <button disabled={loading} onClick={async () => {
                            await setPage((page) => page + 1);
                            // await loadMoreUser();
                        }} type="button" className="w-100 btn btn-lg btn-primary">

                            {
                                loading ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : 'Load More'
                            }
                        </button>
                    </div>
                }
            </div>
        </main >
    );
}

export default UsersScreen;
