import { SAVE_APPROVAL, FETCH_APPROVAL, CHAGE_APPROVAL_STATUS } from '../../actions/approval';

const initialState = {};

export function approvalReducer(state = initialState, action) {
    const { type, payload, meta } = action;
    switch (type) {
        case `${SAVE_APPROVAL}_SUCCESS`: {
            return {
                ...state,
                [payload['data']['data']['status']]: state[payload['data']['data']['status']] ?
                    [...state[payload['data']['data']['status']], payload['data']['data']]
                    :
                    [payload['data']['data']]
            };
        }


        case `${FETCH_APPROVAL}_SUCCESS`: {
            return {
                ...state, [meta['previousAction']['approvals_type']]: payload['data']['data']
            };
        }



        case `${CHAGE_APPROVAL_STATUS}_SUCCESS`: {

            return {
                ...state,
                [meta['previousAction']['approvals_type']]: state[meta['previousAction']['approvals_type']].filter(approval => { return approval['id'] !== payload['data']['data']['id'] }),
                [payload['data']['data']['status']]: state[payload['data']['data']['status']] ? [...state[payload['data']['data']['status']], payload['data']['data']] : [payload['data']['data']]
            };
        }
        default:
            return state;
    }
}
