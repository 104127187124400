
import './style/style.scss';
import 'bootstrap/dist/js/bootstrap.js';
import Header from "./components/Header";
import NavArea from './components/NavArea';
import HomeScreen from './pages/HomeScreen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import AboutScreen from './pages/AboutScreen';
import CurrenciesScreen from './pages/CurrenciesScreen';
import { fetchProfile, fetchCurrencies, triggerLogout } from './actions';
// import { fetchSetting } from './actions/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import LoginScreen from './pages/LoginScreen';
import UsersScreen from './pages/UsersScreen';
import ApprovalScreen from './pages/ApprovalScreen';
import AddApprovalScreen from './pages/AddApprovalScreen';
import { useNavigate } from "react-router-dom";
function App() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth['api_token'] && state.auth['api_token']);
  const logOutAction = () => {
    dispatch(triggerLogout());
  }
  useEffect(() => {
    (async () => {
      await dispatch(fetchProfile());
      await dispatch(fetchCurrencies());


    })();
  }, [dispatch]);
  return (


    <>
      {
        token === undefined ? <LoginScreen /> : <>
          <Header logOutAction={logOutAction} />
          <div className="container-fluid">
            <div className="row">
              <Router>
                <NavArea />
                <Routes>
                  <Route exact path="/" element={<HomeScreen />} />
                  <Route exact path="/currencies" element={<CurrenciesScreen />} />
                  <Route exact path="/users" element={<UsersScreen />} />
                  <Route exact path="/approvals" element={<ApprovalScreen />} />
                  <Route exact path="/approvals/add" element={<AddApprovalScreen />} />
                  {/* <Route exact path="/recovery-password" element={<RecoveryPassword />} />
              <Route path="*" element={<NotFound />} /> */}
                </Routes>
              </Router>
            </div>
          </div>
        </>
      }

    </>
  );
}

export default App;
